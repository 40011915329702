export default {
  createElement(tag, opts){
    opts = opts || {};
    let el = document.createElement(tag);
    for(let key in opts) {
      el[key] = opts[key];
    }
    return el;
  }

}