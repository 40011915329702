<template>
  <div id="basket-72046" class="wdgt-container" ref="container">
    <template>
      <span v-if="!orderDetails.length" class="mb-3">{{ $t('messages.emptyBasket') }}</span>
      <div class="clear-both"></div>
      <table v-if="getOrderDetails.length" class="cremei-table-basket">
        <thead>
        <tr>
          <th class="wdgt-product">{{ $t('words.product') }}</th>
          <th class="wdgt-quantity">{{ $t('words.quant') }}</th>
          <th class="wdgt-price">{{ $t('words.price') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in orderDetails" :key="index+'-'+item.productId">
          <td class="wdgt-product">
            <image-file
                v-if="item.product.imageFile"
                :key="'basket_' + item.product.id"
                :image-file="item.product.imageFile"
                :width="30"
                :height="30"
                :alt="item.product.name"
                class="image"
            />
            <span class="product-text">{{ item.product.name }} <span class="wdgt-date" v-if="item.booking"> - {{ item.booking.startsAt | date }}</span></span>
          </td>
          <td class="wdgt-quantity">
            <template v-if="item.product.type !== 'bookable'">
              <button type="button" @click="addQuantity(item, -1, index)"><i class="material-icons-round">remove</i></button>
              <input
                v-model="item.quantity"
                type="number"
                class="wdgt-quantity"
              />
              <button type="button" @click="addQuantity(item, 1, index)"><i class="material-icons-round">add</i></button>
            </template>
            <template v-else>
              <button type="button" @click="addQuantity(item, -1, index, item.booking.bookingsAvailable)"><i class="material-icons-round">remove</i></button>
              <input
                v-model="item.quantity"
                type="number"
                class="wdgt-quantity"
                :max="item.booking.bookingsAvailable"
              />
              <button type="button" @click="addQuantity(item, 1, index, item.booking.bookingsAvailable)"><i class="material-icons-round">add</i></button>
            </template>
          </td>
          <td class="wdgt-price" v-if="item.booking && hasDiscount(item.product.id, item.booking.startsAt)"><span class="has-discount">{{ item.product.price | currency }}</span> <span v-if="hasDiscount(item.product.id, item.booking.startsAt)" class="wdgt-price-discount wdgt-price"> {{ (item.product.price * item.quantity) - (item.quantity * 2.0661) | currency }}</span></td>
          <td class="wdgt-price" v-else>{{ item.product.price | currency }}</td>
        </tr>
        </tbody>
      </table>

      <table v-if="orderDetails.length" class="cremei-table-basket">
        <thead class="thead-light">
        <tr>
          <th class="text-left w-65">{{ $t('words.taxBase') }}</th>
          <th class="text-center">%</th>
          <th class="text-right">{{ $t('words.total') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="tax in order.getTaxDisclosure()" :key="tax.percent">
          <td class="text-left w-65">{{ tax.base | currency }}</td>
          <td class="text-center">{{ tax.percent }} %</td>
          <td class="text-right nowrap">{{ tax.total | currency }}</td>
        </tr>
        </tbody>
        <tfoot class="font-weight-bold">
        <tr>
          <td colspan="2" class="text-left wdgt-total">{{ $t('words.total') }}</td>
          <td class="wdgt-price wdgt-total">{{ order.getTotal() | currency }}</td>
        </tr>
        </tfoot>
      </table>

      <div class="d-flex payment-buttons">
        <button v-if="total" type="button" @click="payBasket" class="payButton d-flex ml-auto" :disabled="disableCheckoutButton">
          {{ $t('actions.pay') }}
        </button>
      </div>
    </template>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import ImageFile from "@/components/common/ImageFile";
import SweetAlert from "sweetalert2";
import {toLocalTimestamp} from "@/utils/date";
import Swal from "sweetalert2";

export default {
  components: {ImageFile},
  data() {
    return {
      // START SAFAREGI TERMAL : FORCE 50% DISCOUNT IN ONLINE PRODUCTS
      idsProductWithDiscount: [
        '73ffe65e-2bbb-491c-8ecc-67f659c73175'
      ],
      discountStartDate: new Date('2023-06-01 00:00:00'),
      discountEndDate: new Date('2023-09-30 23:59:59'),
      // END SAFAREGI TERMAL : FORCE 50% DISCOUNT IN ONLINE PRODUCTS
      disableCheckoutButton: false
    }
  },
  computed: {
    ...mapGetters('basket', ['order', 'orderDetails']),
    ...mapGetters('session', ['customer']),
    total() {
      return this.order.getTotal()
    },
    getOrderDetails(){
      // clean basket removing orderDetails with older date
      for (let idx in this.orderDetails) {
        if(this.orderDetails[idx].booking){
          if(toLocalTimestamp(new Date()) >= toLocalTimestamp(new Date(this.orderDetails[idx].booking.startsAt))){
            this.doRemoveProduct(idx)
          }
        }
      }
      return this.orderDetails;
    }
  },
  updated() {
    for (const index in this.orderDetails) {
      this.checkQuantity(index, this.orderDetails[index], this.orderDetails[index].booking?.bookingsAvailable)
    }
  },
  methods: {
    ...mapActions('basket', ['removeProduct', 'checkout', 'setQuantity']),
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    },
    doRemoveProduct(index) {
      this.removeProduct(index)
      if (!this.orderDetails.length) {
        this.goBack()
      }
    },
    payBasket() {

      // verifiquem que el preu de la cistella no sigui 0
      let price = 0;
      for (const orderDetail of this.orderDetails) {
        price = price + orderDetail.product.price * orderDetail.quantity
      }

      if (parseInt(price.toString()) === 0) {
        Swal.fire({
          title: 'Falten entrades de pagament',
          text: 'La reserva de visites guiades gratuïtes està vinculada a la compra d\'entrades de pagament. Si us plau, afegeix entrades de pagament a la cistella per poder continuar amb la compra.',
          icon: 'info',
          confirmButtonText: 'D\'acord'
        });
        return false;
      }


      /* merda per no duplicar clicks al boto pagar */
      if (this.disableCheckoutButton) {
        return false
      }
      this.disableCheckoutButton = true
      setTimeout(() => {
        this.disableCheckoutButton = false
      }, 3000);
      /* final de merda  */
      // clean basket removing orderDetails without quantity
      for (let idx in this.orderDetails) {
        if (this.orderDetails[idx].quantity < 1) {
          this.doRemoveProduct(idx)
        }
      }
      if (this.customer) {
        this.checkout();
      } else {
        this.$emit('show-login');
      }
      // show next step
    },
    addQuantity(item, quantity, index, maxUnits = 99999999) {
      item.quantity = parseInt(item.quantity) + parseInt(quantity)
      this.checkQuantity(index, item, maxUnits);
      this.setQuantity({index: index, quantity: item.quantity});
    },
    checkQuantity(index, item, maxUnits = 99999999) {
      item.quantity = parseInt(item.quantity)
      maxUnits = parseInt(maxUnits)
      if (item.quantity > maxUnits) {
        SweetAlert.fire({
            title : this.$t('messages.amountExceeded'),
            html :  this.$t('messages.amountExceededText',{maxUnits:maxUnits,quantity:item.quantity}),
            icon : 'info',
            iconColor : '#c91919',
            confirmButtonColor: '#c91919',
            confirmButtonText: this.$t('actions.accept')
        })
        item.quantity = maxUnits
      }
      if (item.quantity <= 0) {
        this.doRemoveProduct(index)
      }
    },
    goBack() {
      this.$emit('back')
    },
    onInvalid(ev) {
      ev.target.classList.add('touched')
    },
    hasDiscount(productId, startsAt) {
      const hoursDiscount = [14, 21];
      return  this.idsProductWithDiscount.includes(productId) &&
          hoursDiscount.includes(new Date(startsAt).getHours()) &&
          toLocalTimestamp(new Date()) >= toLocalTimestamp(this.discountStartDate) &&
          toLocalTimestamp(new Date()) <= toLocalTimestamp(this.discountEndDate);
    }
  },
  filters: {
    date(date) {
      const d = new Date(date);
      return d.toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric'})
          + ' ' + d.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'})
    }
  }
}
</script>

<style scoped>
button[type="button"],
button[type="submit"],
input[type=checkbox] {
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: auto auto 15px;
}

th,
td {
  border-left: none;
  border-top: none;
  padding: 10px;
  vertical-align: middle;
}
table.cremei-table-basket {
  color: #000;
}
.cremei-table-basket thead{
  border-bottom: 2px solid #CCA20E;
}
.cremei-table-basket thead th{
  font-size: 18px;
}

.wdgt-product-image-head{
  text-align: left;
}
.wdgt-product-image {
  max-width: 30px;
  text-align: left;
}

.wdgt-product {
  width: 60%;
  text-align: left;
}

.wdgt-quantity {
  text-align: center;
}

.wdgt-price {
  text-align: right;
  white-space: nowrap;
}

#_pg-app input.wdgt-quantity {
  -moz-appearance: textfield;
  appearance: textfield;
  margin-bottom: 0;
  display: inline;
  width: 50px;
}

input.wdgt-quantity::-webkit-inner-spin-button,
input.wdgt-quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input.touched:valid {
  /*
  border: inherit !important;
  outline: inherit !important;
  */
}

input.touched:invalid {
  border: 1px solid rgb(156, 33, 25) !important;
  outline: 1px solid rgb(156, 33, 25) !important;
  box-shadow: 0 0 0 0 rgba(156, 33, 25, 1);
  transform: scale(1);
  animation: pulse 2s 50ms;
}
span.product-text {
  margin-left: 20px;
  height: 30px;
  display: inline-block;
  vertical-align: top;
  margin-top: 6px;
}
/* Pulse effect on error */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(156, 33, 25, 0.8);
  }

  50% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba(156, 33, 25, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(156, 33, 25, 0);
  }
}

@media (max-width: 425px) {
  .wdgt-product {
    width: 50%;
  }

  th,
  td {
    padding: 2px;
  }

  td.quantity button {
    display: none;
  }
}

.wdgt-form {
  display: flex;
  flex-wrap: wrap;
}

.form-field {
  margin-bottom: 15px;
  width: 50%;
  padding-right: 15px;
}

.form-field > label {
  display: block;
  margin-bottom: 5px;
}

.form-field.checkbox > label {
  display: inline;
  margin-left: 5px;
}
button.account-button {
  background-color: #a6192e;
  color: white;
  font-size: 20px;
  padding: 10px 40px 10px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.account-button span{
  color: #fff !important;
}
button.account-button{
  float: right;
}

@media (max-width: 768px) {
  .form-field {
    width: 100%;
  }

  .checkout-buttons {
    flex-direction: column;
    justify-items: center;
    margin-top: 10px;
  }

  .checkout-buttons button {
    width: 100%;
    margin: 0 0 15px 0;
  }
}

@media (max-width: 425px) {
  input {
    width: 100%;
    padding: 5px;
  }
}
.wdgt-price .has-discount{
  text-decoration:line-through;
  color:#333 !important;
  font-weight: normal !important;
}
.wdgt-price-discount{
  color: #a81010;
  white-space: nowrap;
}
button.payButton{
  background-color: #CCA20E;
  color: white;
  font-size: 16px;
  padding: 15px 40px 15px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400 !important;
  text-transform: uppercase;
}
button.checkout{
  background: #acacac;
  color: white;
  font-size: 20px;
  padding: 10px 40px 10px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 1rem;
}
.wdgt-quantity button{
  border: 1px solid;
  border-radius: 9999px;
  padding: 0;
  background: none;
  color: #000;
  width: 27px;
  height: 27px;
  vertical-align: middle;
}
#_pg-app input.wdgt-quantity{
  background: none;
}
.wdgt-quantity input{
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;
}
.wdgt-total{
  font-size: 18px;
}
input{
  font-size: 18px;
  padding: 10px;
  margin: 0px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #dedede;
  width: 100%;
}
.checkbox input{
  width: auto;
}
.checkbox label {
  line-height: 25px;
}
.wdgt-form .checkbox label:before{
  background-color: #fafafa;
  border: 1px solid #dedede;
  border-radius: 4px;
  top: 0;
}
.w-65{
  width: 65%;
}
.cremei-table-basket th,
.cremei-table-basket .wdgt-total{
  font-weight: 700;
  color: #000;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
  /*.wdgt-quantity button{
    display: none;
  }*/
  .payment-buttons button,
  .checkout-buttons button{
    width: 100% !important;
    max-width: 100% !important;
    align-items: center;
    justify-content: center;
  }
  .payment-buttons{
    display: block !important;
  }
  .payButton {
    margin-top: 1rem !important;
  }
  button.checkout{
    margin: 0;
  }
}
button:disabled{
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
