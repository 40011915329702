import Vue from 'vue';
import VCalendar from 'v-calendar';
import App from './App.vue';
import store from './store';
import {i18n} from './lang';
import '@/config/filters';
import '@/assets/css.css';

import License from "@/utils/License";

import Products from '@/components/products';
import SFProducts from '@/components/safareigtermal/products';
import VBProducts from '@/components/visitarbodegas/products';
import CRProducts from '@/components/castelldelremei/products';

import MenuItemSession from '@/components/menuItemSession';
import SFMenuItemSession from '@/components/safareigtermal/menuItemSession';
import VBMenuItemSession from '@/components/visitarbodegas/menuItemSession';
import CRMenuItemSession from '@/components/castelldelremei/menuItemSession';

import MenuItemBasket from '@/components/menuItemBasket';
import VBMenuItemBasket from '@/components/visitarbodegas/menuItemBasket';
import SFMenuItemBasket from '@/components/safareigtermal/menuItemBasket';
import CRMenuItemBasket from '@/components/castelldelremei/menuItemBasket';

import Voucher from '@/components/voucher';
import SFVoucher from '@/components/safareigtermal/voucher';
import CRVoucher from '@/components/castelldelremei/voucher';

import {mapGetters} from 'vuex';

Vue.config.productionTip = false;
Vue.use(VCalendar);

let bambuyoAppContainer = window._pg?.target;
if(bambuyoAppContainer === undefined) {
  bambuyoAppContainer = document.createElement('div');
  bambuyoAppContainer.setAttribute('id', 'bambuyo-app');
  document.querySelector('body').appendChild(bambuyoAppContainer);
}
new Vue({
  store,
  i18n,
  render: h => h(App)
}).$mount(bambuyoAppContainer);

document.querySelectorAll('.bambuyo-products').forEach((productsContainer) => {
  const filters = productsContainer.dataset.bambuyoFilters ? JSON.parse(productsContainer.dataset.bambuyoFilters) : {};
  const orders = productsContainer.dataset.bambuyoOrders ? JSON.parse(productsContainer.dataset.bambuyoOrders) : {};

  let productsComponent = Products;
  if (License.isVisitarBodegas()) {
    productsComponent = VBProducts;
  } else if (License.isSafareigTermal()) {
    productsComponent = SFProducts;
  } else if (License.isCastellDelRemei()) {
    productsComponent = CRProducts;
  }

  new Vue({
    el: productsContainer,
    components: {
      Products: productsComponent
    },
    data: {
      "filters": filters,
      "orders": orders
    },
    store,
    i18n,
    template: `
    <div>
      <products :filters="filters" :orders="orders" />
    </div>
  `
  });
});

document.querySelectorAll('.bambuyo-basket-quantity').forEach((element) => {
  new Vue({
    el: element,
    data: {
    },
    computed: {
      ...mapGetters('basket', ['order'])
    },
    store,
    i18n,
    template: `
    <span>
      {{ order.orderDetails.length }}
    </span>
  `
  });
});

document.querySelectorAll('.bambuyo-menu-item-session').forEach((element) => {
  let menuItemSessionComponent = MenuItemSession;
  if (License.isSafareigTermal()) {
    menuItemSessionComponent = SFMenuItemSession;
  } else if (License.isVisitarBodegas()) {
    menuItemSessionComponent = VBMenuItemSession;
  } else if (License.isCastellDelRemei()) {
    menuItemSessionComponent = CRMenuItemSession;
  }

  new Vue({
    el: element,
    components: {
      MenuItemSession: menuItemSessionComponent
    },
    data: {
    },
    store,
    i18n,
    template: `<menu-item-session />`
  });
});

document.querySelectorAll('.bambuyo-menu-item-basket').forEach((element) => {
  let menuItemBasketComponent = MenuItemBasket;
  if (License.isVisitarBodegas()) {
    menuItemBasketComponent = VBMenuItemBasket;
  } else if (License.isSafareigTermal()) {
    menuItemBasketComponent = SFMenuItemBasket;
  } else if (License.isCastellDelRemei()) {
    menuItemBasketComponent = CRMenuItemBasket;
  }
  new Vue({
    el: element,
    components: {
      MenuItemBasket: menuItemBasketComponent
    },
    data: {
    },
    store,
    i18n,
    template: `<menu-item-basket />`
  });
});

document.querySelectorAll('.bambuyo-gift-voucher').forEach((element) => {
  let voucherComponent = Voucher;
  if (License.isSafareigTermal()) {
    voucherComponent = SFVoucher;
  } else if (License.isCastellDelRemei()) {
    voucherComponent = CRVoucher;
  }
  new Vue({
    el: element,
    components: {
      Voucher: voucherComponent
    },
    data: {
    },
    store,
    i18n,
    template: `<Voucher />`
  });
});
