import request from '@/utils/request'

const baseApi = '/private/authentication'

export default class AuthenticationRepository {

  static login(data) {
    return request({
      url: baseApi + '/customer-password-login',
      method: 'post',
      data: data
    })
  }

}
