// import store from '@/store'

export default class License {
    static id = window._pg?.license || process.env.VUE_APP_LICENSE;

    static isVisitarBodegas () {
       return this.id === '625b2072-0248-498c-9eb0-1e9e7e7217a6'
    }

    static isSafareigTermal () {
        return this.id === 'f0d9e19c-7e52-49d7-83f1-fc411830d518'
    }

    static isCastellDelRemei () {
        return this.id === 'e2cb7527-02bb-4f9a-9c7a-b04f85ed5ff2'
    }

}