<template>
  <div class="wdgt-container" ref="container">
    <template>
      <span v-if="!customerBookings" class="mb-3">{{ $t('messages.loading') }}</span>
      <span v-if="customerBookings && customerBookings.length === 0" class="mb-3">{{ $t('messages.emptyModifiableBookings') }}</span>
      <div class="clear-both"></div>
      <table v-if="customerBookings && customerBookings.length > 0">
        <thead>
          <tr>
            <th class="wdgt-date">{{ $t('words.date') }}</th>
            <th class="wdgt-product">{{ $t('words.product') }}</th>
            <th class="wdgt-actions"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in customerBookings" :key="index+'-'+item.orderId">
            <td class="wdgt-date"> {{ item.startsAt | toDate | dateTimeFormat }}</td>
            <td class="wdgt-product">{{ item.orderDetail.product.name }}</td>
            <td class="wdgt-actions">
              <div v-if="isModifiable(item)">
                <a style="cursor: pointer;" href="#" :title="$t('words.modify')" @click="openModalToModifyBooking(item)">
                  <i class="material-icons-round">edit</i> {{ $t('words.modify') }}
                </a>
              </div>
              <div v-else>
                <span>{{ $t('messages.notModifiable') }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <modal v-show="selectedBookingToModify != null" hide-footer size="lg" id="modal-calendar" @close="selectedBookingToModify = null">
        <div slot="header">Calendari</div>
        <div slot="body">
          <SimpleCalendar
            v-if="selectedBookingToModify"
            :product="selectedBookingToModify.orderDetail.product"
            :overrideMinimumMinutesInAdvanceForBooking="selectedBookingToModify.bookingCalendar.timeLimitForDateChangesAndCancellationsInMinutes"
            @bookingSlotSelected="handleBookingSlotSelected"
            @cancel="handleCancel"
            :ref="selectedBookingToModify.id"
          />
        </div>
      </modal>
    </template>
  </div>
</template>
<script>
import Modal from '@/components/modal';
import BookingRepository from "@/api/BookingRepository";
import SimpleCalendar from "@/components/simpleCalendar.vue";
import Swal from "sweetalert2";
export default {
  props: {
    filters: { type:Object, required: false},
    orders: { type:Object, required: false}
  },
  components: {
    SimpleCalendar,
    Modal
  },
  data() {
    return {
      customerBookings : null,
      selectedBookingToModify: null,
    }
  },
  computed: {},
  created() {
    this.getCustomerBookings();
  },

  methods: {
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    },
    async getCustomerBookings() {
      const response = await BookingRepository.customerBookings(this.filters,this.orders);
      this.customerBookings = response.data.items;
    },
    isModifiable(booking) {
      const limitTimestamp = (new Date()).getTime() + (booking.bookingCalendar.timeLimitForDateChangesAndCancellationsInMinutes * 60000);
      return (new Date(booking.startsAt)).getTime() > limitTimestamp;
    },
    openModalToModifyBooking(booking) {
      this.selectedBookingToModify = booking;
    },
    handleBookingSlotSelected(selectedSlot) {
      console.log('handleBookingSlotSelected', selectedSlot);
      BookingRepository.changeDate(this.selectedBookingToModify.id, selectedSlot.startsAt).then(response => {
        console.log('response', response);
        this.getCustomerBookings();
        this.selectedBookingToModify = null;
        Swal.fire({
          title: this.$t('messages.bookingDateChanged'),
          icon: 'success',
          showCancelButton: false
        });
      }).catch(error => {
        Swal.fire({
          title: this.$t('messages.error'),
          text: error.response.data.message,
          icon: 'error',
          showCancelButton: false
        });
      });
    },
    handleCancel() {
      console.log('handleCancel');
    },
  }
}
</script>

<style scoped>

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dedede;
  border-right: none;
  border-bottom: none;
  border-radius: 5px;
  color: #464958;
  margin: auto auto 15px;
  font-size: 16px;
  overflow-x: auto;
  white-space: nowrap;
}

thead {
  background-color: #fafafa;
}

th,
td {
  border: 1px solid #dedede;
  border-left: none;
  border-top: none;
  padding: 5px;
  vertical-align: middle;
}
i.material-icons-round {
  font-size: 16px;
  vertical-align: middle;
}
</style>
