<template>
  <div v-if="!customer" class="lp-join-now">
    <i class="material-icons-round" style="vertical-align: -6px;">person_outline</i>
    <a @click="showLogin()" class="md-trigger">Iniciar sessió</a>
  </div>
  <div v-else class="lp-join-now after-login lp-join-user-info">
    <ul>
      <li>
        <span class="clickable"><i class="material-icons-round">person_outline</i></span>
        <a class="clickable">{{ customer.contactName }}</a>
        <ul class="lp-user-menu list-style-none">
          <li><a class="clickable" onclick="window.dispatchEvent(new CustomEvent('bambuyo:profile'));">El meu perfil</a></li>
          <li><a class="clickable" onclick="window.dispatchEvent(new CustomEvent('bambuyo:my-orders'));">Les meves comandes</a></li>
          <li><a class="clickable" onclick="window.dispatchEvent(new CustomEvent('bambuyo:recovery-pass'));">Canviar contrasenya</a></li>
          <li><a class="clickable" @click="logout()">Tancar sessió</a></li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  components: { },
  props: {
    // style: { type: String, required: false, default: null }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('session', ['customer'])
  },
  methods: {
    ...mapActions('session', ['logout']),
    showLogin() {
      window.dispatchEvent(new CustomEvent('bambuyo:login'));
    },
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    }
  }
}
</script>

<style scoped>
  a.menu-item,
  .menu-item span {
    color: #fff;
    border-color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 44px;
  }
  .clickable {
    cursor: pointer;
  }
</style>
